@import url("https://fonts.googleapis.com/css2?family=Castoro&display=swap");
/*font-family: 'Castoro', serif; */
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;500;600;700;800&display=swap");
/*font-family: 'Encode Sans', sans-serif;*/
/*available weights: 400 -> 800 */



#sidebarLeft {
  position: fixed; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow:hidden;
  white-space: nowrap;
  top: 10px; 
  left: 10px; 
  height: calc(100% - 20px);
  width: 288px;
  background-color: #cc2647;
  border-radius: 10px;
  transition: 0.5s;
  pointer-events: auto;
  font-family: "Encode Sans", sans-serif;
  transition: width 0.3s;
  
}

.sidebarLeft:hover {
  transition: 0.5s;
}

.uw-slant,
.uw-slant-large {
  background-color: #7ac297;
  transform: skew(-25deg, 0);
  display: block;
  width: 120px;
  margin-left: 40px;
  height: 3px;
  margin-bottom: 10px;
  transition: 0.5s;
  flex-shrink: 0;
}

#title-slant {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.uw-slant-large {
  transform: skew(335deg, 0);
  margin-left: 30px;
  width: 79.6%;
  height: 5px;
}
#nav-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: calc(100% - 40px);
}

.header {
  display: block;
  margin-left: 40px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: whitesmoke;
  opacity: 1;
  font-size: 12pt;
  cursor: pointer;
  padding: 5px 5px 2px 2px;
  width: 115px;
}

.header:hover {
  opacity: 0.6;
  transition: 0.2s;
}

.header {
  margin-top: 5px;
}
#title {
  color: #cc2647;
  font-size: 20pt;
  width: 220px;
  box-sizing: border-box;
  margin: auto;
  padding-left: 5px;
  font-weight: 700;
  font-family: "Encode Sans", sans-serif;
  opacity: 1;
  margin-top: 5px;
  margin-bottom: 5px;
}

#titleElements {
  display: block;
  background-color: whitesmoke;
  margin: 5px;
  margin-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: 0.5s;
}

#title-slant {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

#nav-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: calc(100% - 40px);
}

.info-icon {
  display: inline block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-top: 10px;
  float: right;
  filter: invert(100%);
}

.info-icon:hover {
  opacity: 0.6;
  cursor: pointer;
  transition: 0.2s;
}

#nav-popup-container {
  width: 400px;
  height: 60px;
  background-color: white;
  position: absolute;
  border-radius: 10px;
  left: 270px;
  top: 150px;
  padding: 5px;
  box-shadow: 4px 4px 0px 0px #7ac297, 0em 0 0.4em #7ac297;
}

.instruction-header {
  margin: 5px;
}

.underline {
  text-decoration: underline;
}

.is-visible {
  visibility: visible;
}

.is-not-visible {
  visibility: hidden;
}
.inputDiv {
  display: fixed;
  flex-direction: row;
  margin-bottom: 20px;
}

.navBtn {
  border-radius: 5px;
  width: calc(100%);
  height: 40px;
  font-family: "Encode Sans", sans-serif;
  background-color: #7ac297;
  cursor: pointer;
  z-index: 3;
  transition-duration: 0s !important;
}

#navBtn:hover {
  background-color: #7ac297;
  border-color: #7ac297;

  transition: 0.5s;
}
.inputs {
  margin-left: 40px;
}

.contentSection {
  flex-shrink: 0;
  overflow: hidden;
  transition: max-height 0.5s;
  width: calc(90% - 40px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
  ;
}

.contentSection.is-expanded {
  height: auto;
  max-height: 1000px;
}

#navSection {
  height: 160px;
}

.otherComponents {

  position: relative;
}

#githubIcon {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

#githubIcon:hover,
#locationIcon:hover,
#coffeeCupIcon:hover {
  opacity: 0.6;
}

.githubIconDiv {
  margin-right: 10px;
}



#sidebarLeft.closed {
  width: 0;
}

#uw-wordmark-container {
  width: 120px;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

#uw-wordmark {
  width: 120px;
}

.building-container {
  max-height: 200px; /* İstediğiniz yüksekliği burada ayarlayabilirsiniz */
  overflow-y: auto;
  margin-left: 20px /* Fare tekerleği ile kaydırmaya izin verir */
}

.building-container::-webkit-scrollbar {
  width: 0; 
  background: transparent; 
}

.building-list {
  list-style: none;
  padding: 0;
}

.building-item {
  color: white; 
  font-weight: bold;
  font-size: 15px; 
  margin-bottom: 10px; 
  margin-left: 20px;
}



.building-list {
  list-style: none;
  padding: 0;
}



.building-item:hover {
  background-color: lightgray;
}

.searchBar{
  margin-left: 40px;
  border-style: solid;
  border-width: 1px;
  height: 20px;
  border-radius: 0.5em; 
}

.building-item.selected {
  background-color: #e6e6e6;
}
.contentSection.is-expanded {
  height: auto;
  width: calc(100% - 40px);
}