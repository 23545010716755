.sidebarRight {
    position: fixed;
    top:10px;
    height: 100%;
    width: 72px;
    text-align: center;
    left: calc(293px + 10px);
    z-index: 1;
    transition: left 0.3s;
    
  }
  

#logo {
    border-color: #cc2647;
    border-style: solid;
    border-width: 5px;
    margin-top: 0px;
    width: 60px;
    height: 60px;
    padding-top: 0%;
    border-radius: 1em; 
    pointer-events: auto;
    transition: 0.2s;
    cursor: pointer;
    animation: updown 2s 2 forwards;
    animation-delay: 4s;
    background-color: whitesmoke;

 
}

#logo:hover {
    opacity: 0.8;
}

#logo:active {
    transform: translateY(-5px);
    transition: 5s;
}

@keyframes updown {
    0%,
    25%,
    50%,
    75%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
}

.sidebarRight.is-closed {
  left: 0;
}


@media (max-width: 768px) {
  .sidebarRight {
    left: 0;
    top: 10px; /* Adjust this value based on your desired position */
    width: 1%;
    height: auto;
  }
}